import { useNavigate } from "react-router-dom";
import Timestamp from "react-timestamp";
import Tag from "./Tag.component";

export default function BlogCard(props) {
    const {
        title,
        id,
        short_description,
        banner,
        created_at,
        read_time,
        tags,
    } = props.details;
    const navigate = useNavigate();

    const blogTagClickHandler = (tag, isSelected) => {
        navigate(`/blogs?tag=${tag}`);

    };

    const blogCardClickHandler = () => {
        navigate("/blogs/" + id + "/" + title);
    };

    return (
        <div onClick={blogCardClickHandler} className="cursor-pointer">
            <div className="grid grid-cols-3 p-4 gap-2">
                {/* Left container */}
                <div className="flex flex-col text-start col-span-2 gap-2
                    md:gap-4
                ">
                    <div className="">
                        <Tag className="text-xs
                            md:text-base
                            xl:text-lg
                        " name={tags[0]} onClickHandler={blogTagClickHandler} />
                    </div>
                    <div>
                        <h1 className="line-clamp-2 font-extrabold text-xl
                            md:text-2xl
                            xl:text-3xl
                        ">
                            {title}
                        </h1>
                        <p className="mt-2 line-clamp-3 text-sm text-gray-400
                            md:text-base
                            xl:text-xl xl:line-clamp-3
                        ">
                            {short_description}
                        </p>
                    </div>
                    <div className="flex items-center gap-4 *:text-xs 
                        md:*:text-sm
                    ">
                        <Timestamp
                            className="capitalize"
                            relative
                            date={created_at}
                        />
                        <div className="font-bold"> . </div>
                        <span>{read_time} Min Read</span>
                        <div className="hidden font-bold"> . </div>
                        <Tag className="hidden" name={tags} onClickHandler={blogTagClickHandler} />
                    </div>
                </div>

                {/* Right Container */}
                <div className="flex justify-center items-center max-w-32 grow justify-self-center
                    xl:max-w-36
                ">
                    <img
                        src={banner}
                        alt="blog_item_image"
                        className="w-full aspect-square object-cover object-center bg-slate-100"
                    />
                </div>
            </div>
        </div>
    );
}
