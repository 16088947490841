import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

export default function GradientContactCard({message}) {
    const navigate = useNavigate();
  return (
      <div className="flex flex-col items-center gap-6 text-white font-extrabold bg-gradient-to-r from-cblue via-[#00acb2] to-cgreen rounded-2xl p-4 py-8
        md:p-12 md:gap-8
        lg:p-16
         xl:gap-12
      ">
          <h1 className='text-xl
            md:text-2xl
            xl:text-3xl
          '>
              {message}
          </h1>
          <Link
              to={`/contact-us`}
              className="py-2 px-4 text-lg text-cblue hover:text-white font-extrabold bg-white hover:bg-cblue rounded-full cursor-pointer
              "
          >
          Contact Us
          </Link>
      </div>
  );
}
