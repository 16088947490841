import React, { useState } from "react";

const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="border-b border-gray-200 py-4 text-left">
            <div
                className="flex justify-between items-center cursor-pointer gap-4"
                onClick={() => setIsOpen(!isOpen)}
            >
                <h3 className={`text-lg font-medium ${isOpen? "text-cblue":""}`}>{question}</h3>
                <span className="text-xl">{isOpen ? "-" : "+"}</span>
            </div>
            {isOpen && <p className="mt-2 text-gray-500 text-left">{answer}</p>}
        </div>
    );
};

const FAQ = ({faqData}) => {
    return (
        <div className="">
            <h2 className="text-2xl font-bold mb-6">
                Frequently Asked Questions
            </h2>
            {faqData.map((item, index) => (
                <FAQItem
                    key={index}
                    question={item.question}
                    answer={item.answer}
                />
            ))}
        </div>
    );
};

export default FAQ;
