import React, { useEffect } from "react";
import GradientText from "../components/GradientText.component";
import Footer from "../components/Footer.component";
import ServiceCard from "../components/ServiceCard.component.";
import { Navigate, Outlet } from "react-router-dom";
import Service from "./Service.page";
import GradientContactCard from "../components/GradientContactCard.component";
import ImageBanner from "../components/ImageSlider.component";

export const allServicesList = [
            {
                "id": 1,
                "images_list": [
                    "1.png",
                    "1b.png",
                    "1c.png",
                    "1d.png"
                ],
                "title": "Microsoft 365 Deployment and Management",
                "description": "We provide comprehensive deployment, migration, and management services for Microsoft 365, ensuring seamless integration with your existing infrastructure. Our team ensures your organization leverages the full potential of Microsoft 365’s productivity and collaboration tools.",
                "short_description": "We provide comprehensive deployment, migration, and management services for Microsoft 365, ensuring seamless integration with your existing infrastructure.",
                "highlighting_text": "Maximize the benefits of Microsoft 365 with our expert deployment and management services.",
                "service_features": [
                    "Deployment Planning: Develop a strategy for seamless Microsoft 365 deployment and migration.",
                    "Configuration: Configure Microsoft 365 services to align with your business needs.",
                    "User Training: Provide training to ensure effective use of Microsoft 365 applications.",
                    "Integration: Integrate Microsoft 365 with existing systems and workflows.",
                    "Support: Offer ongoing technical support and troubleshooting.",
                    "Optimization: Continuously monitor and optimize the Microsoft 365 environment for performance and security."
                ],
                "why_service": "Our comprehensive Microsoft 365 services ensure smooth deployment, effective management, and optimized use of your Microsoft 365 environment."
            },
            {
                "id": 2,
                "images_list": [
                    "2.png",
                    "2b.png",
                    "2c.png"
                ],
                "title": "Azure Cloud Services",
                "description": "Our Azure Cloud Services include setup, migration, and ongoing management of your cloud infrastructure. We help businesses harness the power of Azure to improve scalability, reduce costs, and enhance security.",
                "short_description": "Our Azure cloud services include setup, migration, and ongoing management of your cloud infrastructure, helping you harness the power of Azure to improve scalability, reduce costs, and enhance security.",
                "highlighting_text": "Leverage Azure’s capabilities for scalable, cost-effective, and secure cloud solutions with our expert services.",
                "service_features": [
                    "Cloud Setup: Assist with the initial setup of your Azure cloud environment.",
                    "Migration Services: Migrate existing applications and data to Azure with minimal disruption.",
                    "Infrastructure Management: Manage and optimize cloud infrastructure for performance and cost efficiency.",
                    "Security Implementation: Implement security measures to protect your cloud resources.",
                    "Scalability Solutions: Design scalable solutions to accommodate growth and changing needs.",
                    "Continuous Monitoring: Monitor and maintain cloud performance to ensure reliability and security."
                ],
                "why_service": "Our Azure cloud services provide expert management and optimization to help you fully leverage Azure’s power while ensuring scalability, security, and cost efficiency."
            },
            {
                "id": 3,
                "images_list": [
                    "3.png",
                    "3b.png",
                ],
                "title": "Security Solutions and Compliance",
                "description": "Protect your business with our advanced security solutions. We offer security assessments, implementation of Microsoft security products, and continuous monitoring to ensure your data and systems are safe from threats. We also ensure your organization meets industry compliance standards.",
                "short_description": "Protect your business with our advanced security solutions. We offer security assessments, implementation of Microsoft security products, and continuous monitoring to ensure your data and systems are secure.",
                "highlighting_text": "Secure your business with our comprehensive security solutions and compliance services.",
                "service_features": [
                    "Security Assessments: Conduct thorough assessments to identify vulnerabilities and risks.",
                    "Microsoft Security Products: Implement and configure Microsoft security solutions to safeguard your environment.",
                    "Continuous Monitoring: Monitor systems continuously to detect and respond to threats.",
                    "Compliance Management: Ensure adherence to industry regulations and standards.",
                    "Incident Response: Provide strategies and support for effective incident response.",
                    "Security Policies: Develop and enforce security policies and procedures to protect data."
                ],
                "why_service": "Our advanced security solutions and compliance services safeguard your business against threats and ensure regulatory adherence, providing peace of mind."
            },
            {
                "id": 4,
                "images_list": [
                    "4.png",
                    "4b.png",
                ],
                "title": "Managed IT Services",
                "description": "Let us handle your IT needs with our managed services. From network management to helpdesk support, we provide proactive maintenance and support to keep your IT infrastructure running smoothly.",
                "short_description": "Let us handle your IT needs with our managed services, ensuring seamless IT operations and support.",
                "highlighting_text": "Streamline your IT operations with our comprehensive managed services, designed for reliability and efficiency.",
                "service_features": [
                    "24/7 Monitoring: Provide round-the-clock monitoring and support for your IT infrastructure.",
                    "Proactive Maintenance: Perform routine maintenance to prevent issues and ensure optimal performance.",
                    "Help Desk Support: Offer responsive help desk support for user and technical issues.",
                    "IT Strategy: Develop IT strategies aligned with your business goals and growth plans.",
                    "Security Management: Manage and implement security measures to protect your IT environment.",
                    "System Upgrades: Handle system upgrades and updates to keep your IT infrastructure current."
                ],
                "why_service": "Our managed IT services deliver reliable support and proactive management, ensuring seamless IT operations and allowing you to focus on your core business activities."
            },
            {
                "id": 5,
                "images_list": [
                    "5.png",
                    "5b.png",
                    "5c.png",
                    "5d.png"
                ],
                "title": "Device Management",
                "description": "Ensure seamless and secure management of all your organization’s devices with our device management services. We provide comprehensive solutions for provisioning, monitoring, and securing desktops, laptops, tablets, and mobile devices, ensuring they operate efficiently and are protected from threats.",
                "short_description": "Ensure seamless and secure management of all your devices with our comprehensive device management services.",
                "highlighting_text": "Optimize device performance and security with our expert device management solutions.",
                "service_features": [
                    "Device Enrollment: Set up and enroll devices into your management system.",
                    "Configuration Management: Configure device settings to meet organizational policies and requirements.",
                    "Security Policies: Implement security measures to protect devices from threats.",
                    "Remote Management: Provide remote management and support for device issues.",
                    "Inventory Management: Track and manage device inventory for effective asset management.",
                    "Update Management: Handle updates and patches to ensure devices remain secure and up-to-date."
                ],
                "why_service": "Our device management solutions ensure that all your devices are secure, properly configured, and efficiently managed, enhancing overall productivity."
            },
            {
                "id": 6,
                "images_list": [
                    "6.png",
                    "6a.png",
                    "6b.png"
                ],
                "title": "Virtual Desktop Infrastructure (VDI) Solutions",
                "description": "Enhance flexibility and security with our VDI solutions. We design and implement VDI environments that allow your employees to access their desktop and applications from anywhere, on any device. Our VDI services improve resource utilization, reduce costs, and provide a secure, consistent user experience.",
                "short_description": "Enhance flexibility and security with our VDI solutions, offering virtual desktops that provide seamless access and management.",
                "highlighting_text": "Boost flexibility and security with our tailored Virtual Desktop Infrastructure solutions.",
                "service_features": [
                    "VDI Setup: Design and implement a VDI environment tailored to your business needs.",
                    "Remote Access: Enable secure remote access to virtual desktops from any location.",
                    "Performance Optimization: Optimize VDI performance for smooth and efficient user experience.",
                    "Security Measures: Implement security protocols to protect virtual desktops and data.",
                    "User Management: Manage user access and permissions for virtual desktops.",
                    "Maintenance and Support: Provide ongoing maintenance and support for the VDI environment."
                ],
                "why_service": "Our VDI solutions offer a secure and flexible desktop environment, enhancing productivity and accessibility while maintaining robust security."
            },
            {
                "id": 7,
                "images_list": [
                    "7.png",
                    "7b.png",
                    "7c.png"
                ],
                "title": "Microsoft Teams Consulting",
                "description": "Enhance collaboration with our Microsoft Teams consulting services. We assist in deployment, customization, and training to ensure your team makes the most of this powerful communication tool.",
                "short_description": "Enhance collaboration with our Microsoft Teams consulting services. We assist in deployment, customization, and training to ensure your team makes the most of this powerful communication tool.",
                "highlighting_text": "Optimize collaboration with expert Microsoft Teams consulting for deployment, customization, and training.",
                "service_features": [
                    "Deployment: Assist with the setup and deployment of Microsoft Teams in your organization.",
                    "Customization: Tailor Microsoft Teams to fit your specific business needs and workflows.",
                    "Training: Provide training to ensure effective use of Microsoft Teams features and capabilities.",
                    "Integration: Integrate Microsoft Teams with other tools and systems for a seamless experience.",
                    "Support: Offer ongoing support to address any issues and optimize performance.",
                    "Best Practices: Share best practices for using Microsoft Teams to enhance collaboration and productivity."
                ],
                "why_service": "Our consulting services ensure that Microsoft Teams is effectively deployed and utilized, enhancing team collaboration and communication."
            },
            {
                "id": 8,
                "images_list": [
                    "8.png",
                    "8b.png",
                    "8c.png"
                ],
                "title": "Data Backup and Recovery",
                "description": "Safeguard your critical data with our backup and recovery solutions. We offer reliable backup strategies and swift recovery options to minimize downtime in the event of data loss or system failures.",
                "short_description": "Safeguard your critical data with our backup and recovery solutions. We offer reliable backup strategies and swift recovery options to minimize downtime in the event of data loss or system failures.",
                "highlighting_text": "Protect your data with our reliable backup and recovery solutions to ensure minimal downtime and quick recovery.",
                "service_features": [
                    "Backup Strategies: Develop and implement effective backup strategies to safeguard data.",
                    "Automated Backups: Set up automated backup processes to ensure regular and reliable data protection.",
                    "Disaster Recovery: Provide swift recovery options in case of data loss or system failures.",
                    "Data Restoration: Restore data efficiently to minimize business disruption.",
                    "Secure Storage: Ensure secure storage of backup data to protect against unauthorized access.",
                    "Regular Testing: Perform regular testing of backup and recovery procedures to ensure reliability."
                ],
                "why_service": "Our backup and recovery solutions provide robust data protection and quick recovery options, minimizing downtime and safeguarding your critical information."
            },
            {
                "title": "IT Support and Helpdesk Services",
                "description": "Our dedicated support team is available to assist with any IT issues, providing fast and effective resolutions. From troubleshooting software problems to hardware repairs, we ensure minimal disruption to your business operations."
            },
            {
                "id": 10,
                "images_list": [
                    "10.png",
                    "10a.png",
                ],
                "title": "Training and Workshops",
                "description": "Empower your staff with our customized training sessions and workshops. We offer training on Microsoft products, cybersecurity best practices, and productivity tools to enhance your team’s skills and efficiency.",
                "short_description": "Empower your staff with our customized training sessions and workshops. We offer training on Microsoft products, cybersecurity best practices, and productivity tools to enhance your team's skills and efficiency.",
                "highlighting_text": "Boost your team’s skills and productivity with our tailored training sessions and workshops.",
                "service_features": [
                    "Microsoft Products Training: In-depth training on Microsoft 365, Azure, SharePoint, and Power BI.",
                    "Cybersecurity Awareness: Educate employees on cybersecurity threats, best practices, and incident response.",
                    "Productivity Tools: Train on time management, collaboration tools, and automation techniques.",
                    "Customized Workshops: Design and deliver workshops based on specific organizational needs.",
                    "Interactive Learning: Use hands-on exercises and simulations for effective learning.",
                    "Ongoing Support: Provide continued support and resources to reinforce learning."
                ],
                "why_service": "Our training and workshops enhance your team's skills and efficiency, empowering them with the knowledge needed to excel in their roles and boost overall productivity."
            },
            {
                "id": 11,
                "images_list": [
                    "11.png",
                    "11b.png",
                ],
                "title": "Network Design and Implementation",
                "description": "Our experts design and implement robust and scalable network solutions tailored to your business needs. We ensure optimal performance, reliability, and security for your network infrastructure."
            },                          
            {
                "id": 13,
                "images_list": [
                    "13.png",
                    "13b.png",
                    "13c.png",
                    "13d.png"
                ],
                "title": "Software Licensing and Asset Management",
                "description": "Manage your software assets efficiently with our licensing and asset management services. We help you stay compliant, optimize license usage, and reduce software costs.",
                "short_description": "Manage your software assets efficiently with our licensing and asset management services. We help you stay compliant, optimize license usage, and reduce software costs.",
                "highlighting_text": "Ensure compliance and maximize cost savings with our expert software management solutions.",
                "service_features": [
                    "License Compliance: Verify and maintain compliance with software licensing agreements.",
                    "Usage Optimization: Analyze and optimize license usage to reduce unnecessary costs.",
                    "Asset Tracking: Track and manage all software assets and their lifecycle.",
                    "Cost Management: Implement strategies to minimize software expenses.",
                    "Vendor Management: Manage relationships with software vendors for better terms and support.",
                    "Regular Audits: Conduct periodic audits to ensure ongoing compliance and efficiency."
                ],
                "why_service": "Our comprehensive approach guarantees effective management of software assets, ensuring compliance and reducing overall costs."
            },
            {
                "id": 14,
                "images_list": [
                    "14.png",
                    "14b.png",
                    "14-c.png"
                ],
                "title": "Cybersecurity Training and Awareness",
                "description": "Educate your employees on the latest cybersecurity threats and best practices. Our training programs are designed to create a security-conscious culture within your organization.",
                "short_description": "Educate your employees on the latest cybersecurity threats and best practices. Our training programs create a security-conscious culture within your organization.",
                "highlighting_text": "Build a robust security culture with our tailored cybersecurity training solutions.",
                "service_features": [
                    "Threat Awareness: Train employees to recognize and respond to common cybersecurity threats.",
                    "Best Practices: Teach industry best practices for data protection and secure behavior.",
                    "Incident Response: Equip staff with skills to handle and report security incidents.",
                    "Data Protection: Educate on secure data handling and encryption techniques.",
                    "Regular Updates: Keep employees informed about the latest threats and updates.",
                    "Interactive Training: Utilize hands-on simulations and real-life scenarios for effective learning."
                ],
                "why_service": "Our training programs enhance your team’s ability to prevent and respond to security threats, fostering a safer organizational environment."
            },
            {
                "id": 15,
                "images_list": [
                    "15.png",
                    "15b.png",
                    "15c.png"
                ],
                "title": "Full Stack Development",
                "description": "Our team of experienced developers offers end-to-end full stack development services, including frontend and backend development, database management, and API integration. We build scalable, robust, and high-performance web and mobile applications tailored to your business needs.",
                "short_description": "Our experienced developers offer end-to-end full stack development services, including frontend and backend development, database management, and API integration.",
                "highlighting_text": "Build scalable and robust applications with our comprehensive full stack development services.",
                "service_features": [
                    "Frontend Development: Create user-friendly and responsive interfaces using modern technologies.",
                    "Backend Development: Develop robust server-side logic and database integration.",
                    "Database Management: Design and manage databases to ensure efficient data handling.",
                    "API Integration: Connect with third-party services through secure and efficient APIs.",
                    "Scalability: Build solutions that scale with your business growth and demand.",
                    "Quality Assurance: Implement rigorous testing to ensure application reliability and performance."
                ],
                "why_service": "Our full stack development services deliver high-quality, scalable solutions that meet your business needs from start to finish."
            },
            {
                "id": 17,
                "images_list": [
                    "17.png",
                    "17b.png",
                    "17c.png"
                ],
                "title": "Data Science and Analytics",
                "description": "Transform your data into actionable insights with our data science and analytics services. We offer data mining, statistical analysis, and data visualization solutions to help you make data-driven decisions.",
                "short_description": "Transform your data into actionable insights with our data science and analytics services.",
                "highlighting_text": "Unlock the potential of your data to drive strategic decisions with our advanced analytics solutions.",
                "service_features": [
                    "Data Analysis: Analyze data to uncover trends and insights for informed decision-making.",
                    "Predictive Modeling: Use statistical models to forecast future outcomes and trends.",
                    "Data Visualization: Create intuitive visualizations to present complex data clearly.",
                    "Machine Learning: Implement machine learning algorithms to enhance data processing.",
                    "Data Integration: Combine data from multiple sources for comprehensive analysis.",
                    "Report Generation: Generate detailed reports to communicate findings effectively."
                ],
                "why_service": "Our data science and analytics expertise enables you to harness the power of your data for strategic insights and better business outcomes."
            },
            {
                "id": 18,
                "images_list": [
                    "18.png",
                    "18b.png",
                    "18c.png"
                ],
                "title": "Chatbot Development",
                "description": "Enhance customer engagement with our intelligent chatbot solutions. We design and develop AI-powered chatbots that can handle customer queries, provide support, and improve user experience on your website or app.",
                "short_description": "Enhance customer engagement with our intelligent chatbot solutions. We design and develop AI-powered chatbots tailored to your business needs.",
                "highlighting_text": "Improve customer interactions and operational efficiency with our advanced chatbot solutions.",
                "service_features": [
                    "Custom Design: Create chatbots tailored to your specific business requirements and use cases.",
                    "Natural Language Processing: Implement NLP for understanding and responding to user queries.",
                    "Integration: Seamlessly integrate chatbots with existing systems and platforms.",
                    "Automation: Automate routine tasks and inquiries to improve efficiency.",
                    "User Experience: Design chatbots to provide a smooth and engaging user experience.",
                    "Analytics and Reporting: Track chatbot performance and gather insights for continuous improvement."
                ],
                "why_service": "Our AI-powered chatbots enhance customer engagement and streamline operations, delivering tailored solutions that drive business success."
            },
            {
                "id": 19,
                "images_list": [
                    "19.png",
                    "19b.png",
                    "19c.png"
                ],
                "title": "Business Intelligence Solutions",
                "description": "Make informed business decisions with our business intelligence solutions. We implement BI tools that gather, analyze, and visualize data, providing you with comprehensive insights into your business performance.",
                "short_description": "Make informed business decisions with our business intelligence solutions. We implement BI tools that gather, analyze, and visualize data, providing comprehensive insights.",
                "highlighting_text": "Leverage advanced BI tools to turn data into actionable insights and drive strategic decisions.",
                "service_features": [
                    "Data Integration: Combine data from various sources for a unified view.",
                    "Analytics: Analyze data to identify trends and gain insights.",
                    "Visualization: Create visual representations of data for easy interpretation.",
                    "Dashboard Development: Develop interactive dashboards for real-time data monitoring.",
                    "Reporting: Generate detailed reports to support decision-making processes.",
                    "Performance Metrics: Track key performance indicators to measure business performance."
                ],
                "why_service": "Our business intelligence solutions provide valuable insights through data analysis and visualization, empowering you to make informed and strategic decisions."
            },
            {
                "id": 20,
                "images_list": [
                    "20.png",
                    "20b.png",
                    "20c.png"
                ],
                "title": "Robotic Process Automation (RPA)",
                "description": "Automate repetitive tasks and streamline operations with our RPA services. We deploy bots that can handle routine tasks, freeing up your staff to focus on more strategic activities.",
                "short_description": "Automate repetitive tasks and streamline operations with our RPA services. We deploy bots to handle routine tasks, freeing up your staff to focus on more strategic activities.",
                "highlighting_text": "Enhance efficiency by automating repetitive tasks with our intelligent RPA solutions.",
                "service_features": [
                    "Bot Development: Design and develop custom bots to automate specific tasks.",
                    "Process Analysis: Analyze workflows to identify opportunities for automation.",
                    "Integration: Integrate RPA solutions with existing systems and processes.",
                    "Deployment: Deploy bots and manage their operation within your environment.",
                    "Monitoring: Monitor bot performance and efficiency to ensure optimal operation.",
                    "Support: Provide ongoing support and maintenance for RPA solutions."
                ],
                "why_service": "Our RPA services streamline operations and boost productivity by automating repetitive tasks, allowing your team to focus on higher-value activities."
            },
            {
                "id": 21,
                "images_list": [
                    "21.png",
                    "21b.png",
                    "21c.png"
                ],
                "title": "Natural Language Processing (NLP)",
                "description": "Utilize our NLP expertise to extract meaningful information from unstructured data. Our NLP solutions include text analysis, sentiment analysis, and language translation services.",
                "short_description": "Utilize our NLP expertise to extract meaningful information from unstructured data. Our NLP solutions include text analysis, sentiment analysis, and language translation services.",
                "highlighting_text": "Unlock valuable insights from unstructured data with our advanced NLP solutions.",
                "service_features": [
                    "Text Analysis: Extract key information and insights from textual data.",
                    "Sentiment Analysis: Analyze sentiment in text to gauge opinions and emotions.",
                    "Language Translation: Translate text across multiple languages for broader communication.",
                    "Named Entity Recognition: Identify and categorize entities within text for better understanding.",
                    "Speech Recognition: Convert spoken language into text for analysis and action.",
                    "Custom NLP Models: Develop tailored NLP models to meet specific business needs."
                ],
                "why_service": "Our NLP solutions enable you to derive actionable insights from unstructured data, enhancing decision-making and communication."
            },
            {
                "id": 22,
                "images_list": [
                    "22.png",
                    "22b.png",
                    "22c.png"
                ],
                "title": "Computer Vision Solutions",
                "description": "Implement advanced computer vision technologies to enhance your business processes. Our solutions include image recognition, object detection, and video analytics for various applications.",
                "short_description": "Implement advanced computer vision technologies to enhance your business processes. Our solutions include image recognition, object detection, and video analytics.",
                "highlighting_text": "Transform your processes with cutting-edge computer vision technologies for improved efficiency and accuracy.",
                "service_features": [
                    "Image Recognition: Identify and classify objects within images for various applications.",
                    "Object Detection: Detect and locate objects in images and videos.",
                    "Video Analytics: Analyze video data for insights and automated actions.",
                    "Facial Recognition: Implement facial recognition technology for security and identification.",
                    "Pattern Recognition: Detect patterns and anomalies in visual data for analysis.",
                    "Custom Solutions: Develop tailored computer vision solutions to address specific business needs."
                ],
                "why_service": "Our computer vision solutions enhance your business processes with advanced image and video analysis, providing accuracy and efficiency."
            },
]

const findServiceByTitleLoader = ({ params }) => {
    return allServicesList.find((service) => service.title == params.service_title);
}

export const services_routes = [
    {
        path : '',
        element : <AllServices/>,
    },
    {
        path: ':service_title',
        element : <Service/>,
        loader : findServiceByTitleLoader
    },
    {
        path: '*',
        element: <Navigate to="/not-found" />,
    }
];

export default function Services() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Services | QUBITWARE"
    }, []);

    return (
        <>
            <Outlet/>
            <Footer varient="blue" />
        </>
    );
}

function AllServices() {
    const generateBannerImagesList = () => {
        const imagesList = [];
        allServicesList.forEach((service) => {
            imagesList.push(`/service_imgs/${service.id}.png`);
        })
        return imagesList;
    }
    return (
        <div className="flex flex-col items-center">
            {/* Banner */}
            <section>
                <div className="container mx-auto py-16 grid grid-cols-1
                    2xl:py-32 xl:grid-cols-2
                ">
                    <div className="flex flex-col items-center gap-y-4 ">
                        <GradientText
                            className="text-5xl font-extrabold py-2
                        md:text-6xl
                        lg:text-7xl
                        xl:text-8xl
                    "
                            children={"Services"}
                        />
                        <p className="text-xl text-gray-500 mb-12
                        md:text-xl
                        lg:text-2xl
                    ">
                            Fuel your <b>organization's growth and innovation</b>
                            <br /> with the comprehensive suite of professional services
                            <br /> And end-to-end support at <br />
                            <GradientText children={"QUBITWARE"} />
                        </p>
                    </div>
                    <div className="col-span-1">
                        <ImageBanner images={generateBannerImagesList()} />
                    </div>
                </div>
            </section>

            {/* All Services section */}
            <section className="bg-neutral-100 rounded-2xl py-12
                lg:py-16
                ">
                <div className="container mx-auto flex flex-col text-left gap-8
                ">
                    <div className="flex flex-col gap-2">
                        <GradientText
                            className="text-4xl font-extrabold
                        lg:text-5xl
                    "
                            children={"Our Offerings"}
                        />
                        {/* TODO : change the p tag text */}
                        <p className="text-lg text-slate-500
                        lg:text-xl
                        xl:text-2xl
                    ">
                        Reliable customized IT Services designed to support your business and give you peace of mind.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 gap-6
                            sm:grid-cols-2
                            md:gap-8
                            lg:grid-cols-2
                            xl:grid-cols-3
                            2xl:grid-cols-4 
                        ">
                        {allServicesList.map((service, index) => (

                            <ServiceCard
                                key={index}
                                title={service.title}
                                description={service.description}
                                img_url={`/service_imgs/${service.id}.png`}
                            />
                        ))}
                    </div>
                </div>
            </section>

            {/* CONTACT US */}
            <section>
                <div className="container mx-auto my-12
                    lg:my-8
                    ">
                    <GradientContactCard message={"Ready to get started?"} />
                </div>
            </section>
        </div>
    );
}
