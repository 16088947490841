import { useState } from "react";
import Input from "./Input.component";

export default function ContactForn({ formClasses = "" }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        message: "",
    });

    const inputFields = [
        {
            label: "First Name",
            name: "firstName",
            required: true,
            pattern: "[A-Za-z]{2,}",
        },
        {
            label: "Last Name",
            name: "lastName",
            required: true,
            pattern: "[A-Za-z]{2,}",
        },
        {
            label: "Email",
            name: "email",
            required: true,
            type: "email",
            pattern: "[^@s]+@[^@s]+.[^@s]+",
        },
        {
            label: "Mobile",
            name: "mobile",
            required: true,
            type: "tel",
            pattern: "[0-9]{10}",
        },
    ];

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const resetForm = () => {
        setFormData({
            firstName: "",
            lastName: "",
            email: "",
            mobile: "",
            message: "",
        });
    };

    function captchaResponseCallback(token) {
        document.getElementById("contactForm").submit();
        console.log(token)
    }

    const handleSubmit =  async (e) => {
        e.preventDefault();
        captchaResponseCallback();
        setIsSubmitting(true);
        const response = await new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve("Message sent successfully");
            }, 3000);
        });
        console.log(response)
        setIsSubmitting(false);
        console.log(formData);
        // resetForm();
    };
    return (
        <form
            noValidate
            id="contactForm"
            className={`group ${formClasses}`}
            onSubmit={handleSubmit}
            action="submit"
        >
            <div className="grid grid-cols-2 p-4 gap-8">
                {inputFields.map((inputField) => (
                    <Input
                        key={inputField.name}
                        {...inputField}
                        value={formData[inputField.name]}
                        onChange={handleChange}
                    />
                ))}
                {/* Message */}
                <div className="flex col-start-1 col-end-3 flex-col text-start font-semibold text-gray-400 border-gray-300">
                    <textarea
                        name="message"
                        className="order-2 p-2 pl-0 text-sm text-black focus:outline-none border-b-2 h-32 resize-none focus:border-cblue [&:not(:placeholder-shown)]:border-cblue placeholder:text-gray-300 peer"
                        placeholder="Write your message..."
                        value={formData.message}
                        onChange={handleChange}
                    ></textarea>
                    <label
                        className="order-1 text-sm peer-focus:text-cblue peer-[&:not(:placeholder-shown)]:text-cblue 
                            md:text-base
                        "
                        htmlFor="message"
                    >
                        Message
                    </label>
                </div>
            </div>
            <button
                class="g-recaptcha"
                data-sitekey="6Lfgri4qAAAAAAA9IAot09iKKCIVl_fYEXr6yST7"
                data-callback='captchaResponseCallback  '
                data-action='submit'
                className="bg-cblue text-white py-2 w-fit px-4 rounded-2xl ml-auto group-invalid:pointer-events-none group-invalid:opacity-50"
            >
                {isSubmitting ? "Sending..." : "Send Message"}
            </button>
        </form>
    );
}
