import logo_main from "../assets/logo/Main-Logo.png";
import logo_light from "../assets/logo/Main-logo-White-Text.png";
import { Link } from "react-router-dom";
import { IoCallSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";

export default function Footer({ varient = ""}) {
    return (
        <section
            className={`py-12 rounded-t-2xl ${
                varient === "blue"
                    ? "bg-cblue text-white"
                    : "bg-neutral-100 text-black"
            } 
            `}
        >
            <div className="container mx-auto flex flex-col gap-6 
            md:gap-8
            ">
                {/* Logo */}
                <div className="self-center text-center
                xl:hidden
            ">
                    <img
                        className="h-20"
                        src={varient === "blue" ? logo_light : logo_main}
                        alt="Logo"
                    />
                </div>

                {/* Horizontal Rule */}
                <hr
                    className={`w-full border-t-[1px] border-solid ${varient === "blue" ? "border-white" : "border-t-gray-300"
                        }
                xl:hidden
                `}
                />

                {/* Footer items */}
                <div className="grid grid-cols-2 gap-y-8 gap-x-4
                md:grid-cols-3 md:gap-x-8
                xl:grid-cols-4 xl:pt-12 xl:gap-12
            ">
                    {/* Logo */}
                    <div className="hidden self-center text-center
                        xl:flex xl:col-span-2 
                    ">
                        <img
                            className="h-32"
                            src={varient === "blue" ? logo_light : logo_main}
                            alt="Logo"
                        />
                    </div>

                            {/* contact details with icons in the beginning */}
                            <div className="flex flex-col text-sm gap-2 text-left">
                                <h1 className="text-base font-bold
                                    md:text-lg
                                ">Reach Us</h1>
                                {[
                                    {
                                        icon: <IoCallSharp className={`size-4 self-center ${varient === "blue" ? "text-white" : "text-black"} xl:size-6`} />,
                                        text: "+91 9354407238"
                                    },
                                    {
                                        icon: <MdEmail className={`size-4 self-center ${varient === "blue" ? "text-white" : "text-black"} xl:size-6`} />,
                                        text: (
                                            <>
                                                helpdesk@<br className="xl:hidden"/>
                                                qubitware.in
                                            </>
                                        )
                                    },
                                    {
                                        icon: <FaLocationDot className={`size-4 self-center ${varient === "blue" ? "text-white" : "text-black"} xl:size-6`} />,
                                        text: (
                                            <>
                                                Bishanawala, Jaipur
                                                <br />
                                                Panchyawala Jaipur
                                                <br />
                                                -302034
                                                Rajasthan
                                            </>
                                        )
                                    }
                                ].map((item, index) => (
                                    <div key={index} className="flex justify-start gap-2 
                                        md:text-base">
                                        {item.icon}
                                        <span>{item.text}</span>
                                    </div>
                                ))}
                            </div>
                            {/* Site map */}
                            <div className="flex flex-col text-sm gap-2 text-right
                                md:text-left
                            ">
                                <h1 className="text-base font-bold
                                    md:text-lg
                                ">Company</h1>
                                {
                                    [
                                        { path: "/services", label: "Services" },
                                        { path: "/partners", label: "Partners" },
                                        { path: "/", label: "About Us" },
                                        { path: "/blogs", label: "Blogs" },
                                    ]
                                    .map((link, index) => (
                                        <Link
                                            key={index}
                                            className="md:text-base 
                                            "
                                            to={link.path}
                                        >
                                            {link.label}
                                        </Link>
                                    ))
                                }
                            </div>
                            {/* legal policies links */}
                            <div className="flex flex-col text-sm gap-2 text-left">
                                <h1 className="text-base font-bold
                                    md:text-lg
                                ">Legal</h1>
                                {[
                                    { path: "/privacypolicy", label: "Privacy Policy" },
                                    { path: "/terms-of-service", label: "Terms of Service" },
                                    { path: "/refund-policy", label: "Refund Policy" }
                                ].map((link, index) => (
                                    <Link
                                        key={index}
                                        className="md:text-base"
                                        to={link.path}
                                    >
                                        {link.label}
                                    </Link>
                                ))}
                            </div>
                            {/* Quick links */}
                            <div className="flex flex-col text-sm gap-2 text-right
                                md:text-left
                            ">
                                <h1 className="text-base font-bold
                                    md:text-lg
                                ">Quick Links</h1>
                                {[
                                    { path: "/contact-us#contact-us-section", label: "Contact Us" },
                                    { path: "/careers", label: "Careers" },
                                    { path: "/contact-us#faq-section", label: "FAQs" }
                                ].map((link, index) => (
                                    <Link
                                        key={index}
                                        className="md:text-base"
                                        to={link.path}
                                    >
                                        {link.label}
                                    </Link>
                                ))}
                            </div>
                    {/* Join newsletter */}
                    <div
                        className={`flex flex-col shadow-2xl w-fit col-span-2 text-sm gap-2 p-4 rounded-lg mx-auto  ${varient === "blue" ? "bg-white" : "bg-cblue"
                                }
                        md:h-fit md:p-8 md:gap-4
                        xl:row-start-2 xl:px-10
                        2xl:row-start-1 2xl:col-start-3
                        `}
                    >
                        <h1
                            className={`text-base font-bold ${varient === "blue" ? "text-black" : "text-white"
                                    }
                            md:text-lg
                            xl:text-xl
                            `}
                        >
                            Join Newsletter
                        </h1>
                        <form id="newsletter-form" className="flex w-full mx-auto
                            xl:text-base">
                            <input
                                type="email"
                                className={`w-full rounded-s-md p-2 text-black focus:outline-none invalid:outline-2  ${varient === "blue" ? "bg-gray-300" : "bg-white"
                                    }`}
                                placeholder="Enter your Email.. "
                                pattern="[^@s]+@[^@s]+.[^@s]+"
                            />
                            <button
                                className={`p-2 rounded-e-md font-medium text-white ${varient === "blue" ? "bg-cblue" : "bg-cgreen"
                                    }`}
                            >
                                Subscribe
                            </button>
                        </form>
                        <p className="text-slate-400 *:
                            md:-m-2
                            xl:text-sm           
                        ">
                            * will send you weeky updates of our stories
                        </p>
                    </div>
                    {/* Horizontal Rule */}
                    <hr
                        className={`flex col-span-2 w-full border-t-[1px] border-solid ${varient === "blue" ? "border-white" : "border-t-gray-300"
                            }
                        md:col-span-3
                        xl:flex xl:col-span-4
                        `}
                    />
                </div>
                <div>
                    <p className="text-base text-slate-400
                    xl:text-xl
                    ">
                        © 2024 | QUBITWARE | All rights reserved
                    </p>
                </div>
            </div>
        </section>
    );
}
