import { useEffect } from "react";
import GradientText from "../components/GradientText.component";
import ContactForn from "../components/ContactForn.component";
import { IoCallSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { FaInstagram, FaXTwitter, FaLinkedin } from "react-icons/fa6";
import Footer from "../components/Footer.component";
import FAQ from "../components/Faq.component";
import ScrollToSection from '../utils/ScrollToSection'

const faqData = [
    {
        question: "What is Qubitware?",
        answer:
            "Qubitware is a web development and digital marketing company that specializes in creating custom websites and mobile applications for businesses. We help our clients reach their target audience and grow their business through digital marketing strategies.",
    },
    {
        question: "How can I contact Qubitware?",
        answer:
            "You can contact Qubitware by phone at +123 456 789, by email at helpdesk at jmt.com or by visiting our office at Bay, Nawalgarh, Sikar, Rajasthan.",
    },
    {
        question: "What services does Qubitware offer?",
        answer:
            "Qubitware offers a wide range of services, including web development, mobile app development, digital marketing, SEO services, content writing, graphic design, UI/UX design, e-commerce solutions, cloud computing, and cybersecurity.",
    },
    {
        question: "How can I request a quote from Qubitware?",
        answer:
            "You can request a quote from Qubitware by filling out the contact form on our website or by contacting us directly. Our team will get back to you with a customized quote based on your requirements.",
    },
    {
        question: "Does Qubitware offer custom solutions?",
        answer:
            "Yes, Qubitware offers custom solutions tailored to your business needs. Our team of experts will work with you to create a website or mobile application that meets your specific requirements and goals.",
    },
];

export default function ContactUs() {
    useEffect(() => {
        document.title = "Contact Us | QUBITWARE"
        // window.scrollTo(0, 0);
    }, []);
    
    return (
        <div className="flex flex-col gap-12
            md:py-6
        ">
            <ScrollToSection/>  
            {/* contact form */}
            <section
                id="contact-us-section"
                className="py-2">
                <div className="container mx-auto grid grid-cols-1 gap-6 max-w-6xl">
                    <div className="flex flex-col gap-2">
                        <GradientText className="text-4xl font-bold
                            2xl:text-5xl
                        ">
                            Contact Us
                        </GradientText>
                        <h1 className="text-lg text-slate-500
                            md:text-xl
                        ">
                            Any questions or remarks? Just write us a message!
                        </h1>
                    </div>
                    <div className="grid grid-cols-1 justify-between shadow-2xl rounded-2xl bg-white p-4
                        md:p-8 md:gap-6
                        lg:grid-cols-2
                    ">
                        {/* left container */}
                        <div className="bg-cblue rounded-t-2xl text-white p-4 text-left flex flex-col justify-between gap-6
                            md:gap-8 md:p-8
                            lg:rounded-none lg:rounded-s-2xl
                        ">
                            {/* Heading */}
                            <div>
                                <h1 className="text-2xl font-bold">
                                    Contact Information
                                </h1>
                                <p className="text-base text-gray-300">
                                    Let's start with a conversation
                                </p>
                            </div>

                            {/* Contact Details */}
                            <div className="flex flex-col gap-2 text-md font-base text-gray-200">
                                {[
                                    {
                                        icon: <IoCallSharp className="size-4 self-center text-white" />,
                                        text: "+91 9354407238"
                                    },
                                    {
                                        icon: <MdEmail className="size-4 self-center text-white" />,
                                        text: (
                                            <>
                                                helpdesk@<br className="xl:hidden"/>
                                                qubitware.in
                                            </>
                                        )
                                    },
                                    {
                                        icon: <FaLocationDot className="size-4 self-center text-white" />,
                                        text: (
                                            <>
                                                Bishanawala, Jaipur
                                                <br />
                                                Panchyawala Jaipur
                                                <br />
                                                -302034
                                                Rajasthan
                                            </>
                                        )
                                    }
                                ].map((item, index) => (
                                    <div key={index} className="flex justify-start gap-6">
                                        {item.icon}
                                        <span>{item.text}</span>
                                    </div>
                                ))}
                            </div>
                            {/* Social Links */}
                            <div className="flex justify-start gap-6">
                                {[
                                    { icon: <FaInstagram className="w-6 h-6 hover:hover_border duration-100" /> },
                                    { icon: <FaXTwitter className="w-6 h-6 hover:hover_border duration-100" /> },
                                    { icon: <FaLinkedin className="w-6 h-6 hover:hover_border duration-100" /> }
                                ].map((item, index) => (
                                    <div key={index} className="flex items-center">
                                        {item.icon}
                                    </div>
                                ))}
                            </div>  
                        </div>

                        {/* Right container */}
                        <ContactForn
                            formClasses="py-4"
                        />
                    </div>
                </div>
            </section>
            <section id="faq-section" >
                <div className="container mx-auto my-6 max-w-5xl
                ">
                    <FAQ faqData={faqData} />
                </div>
            </section>
            <Footer varient="blue" />
        </div>
    );
}
