const Input = ({
    label,
    className = "",
    ...inputProps
}) => {

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    }

    return (
        <div
            className={`flex flex-col text-start text-sm text-gray-400 ${className}
                md:text-base
            `}
        >
            <input
                className="w-full order-2 border-b-2 p-2 pl-0 focus:outline-none text-black font-extrabold invalid:[&:not(:placeholder-shown):not(:focus)]:border-red-500 focus:border-cblue [&:not(:placeholder-shown)]:border-cblue peer"
                placeholder=" "
                onKeyDown={handleKeyDown}
                {...inputProps}
            />
            <label
                className="peer w-full font-semibold peer-[&:not(:placeholder-shown):not(:focus):invalid]:text-red-500 peer-focus:text-cblue peer-[&:not(:placeholder-shown)]:text-cblue"
            >
                {label}
                {inputProps.required && "*"}
            </label>
        </div>
    );
};

export default Input;
