import React from 'react';
import Timestamp from 'react-timestamp'
import Tag from '../components/Tag.component';
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const BlogCardBig = ({variant = "vertical", blogData, className=""}) => {
    const navigate = useNavigate();
    return (
        <div className={`
            ${className} grid ${variant == "horizontal"? "grid-cols-2 ":"grid-cols-1"}  
            gap-4 h-fit group
            xl:gap-8
        `}>
            {/* Image-container */}
            <div className={`${variant == "horizontal"? "h-full" : "w-full"} relative`}>
                <img
                    src={blogData.banner}
                    alt="blog_image"
                    className={`${variant == "horizontal" ? "h-full" : "w-full aspect-video"} object-cover object-center`}
                />
            </div>
            {/* text - container */}
            <div className="flex flex-col gap-4 w-full">
                <h1 className="line-clamp-2 font-extrabold text-3xl">
                    {blogData.title}
                </h1>
                <p className="line-clamp-2 text-slate-400 text-lg lg:line-clamp-4">
                    {blogData.short_description}
                </p>
                <div className="flex items-center gap-2 *:text-sm xl:*:text-sm">
                    <Timestamp relative date={blogData.created_at} />
                    <div className="font-bold"> . </div>
                    <span>{blogData.read_time} Mins Read</span>
                    <div className="font-bold"> . </div>
                    <Tag name={blogData.tags[0]} onClickHandler={undefined} />
                </div>
                <button className="mr-auto mt-auto p-2 text-3xl -rotate-45 text-cblue rounded-full hover:bg-cblue bg-white/0 hover:text-white hover:rotate-0 duration-300 group-hover:animate-bounce"
                    onClick={() => {
                        navigate("/blogs/" + blogData.id + "/" + blogData.title);
                    }}
                >
                    <FaArrowRight />
                </button>
            </div>
        </div>
    );
};

export default BlogCardBig;
