import ServiceCard from "../components/ServiceCard.component.";
import { Link, useLoaderData } from "react-router-dom";
import GradientText from "../components/GradientText.component";
import { useEffect } from "react";
import Footer from "../components/Footer.component";
import GradientContactCard from "../components/GradientContactCard.component";
import { useNavigate } from "react-router-dom";
import AnimationWrapper from "../components/AnimationWrapper.component";
import BlogCardBig from "../components/BlogCardBig.component";
import axios  from "axios";
import { allServicesList } from "./Services.page";

export const fetchXLatestBlogs = async (blog_count=4) => {
    return await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/blog/4/`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};


export default function Home() {
    const navigate = useNavigate();
    const services = allServicesList.slice(0,4);
    const blogsData = useLoaderData();
    console.log(process.env.REACT_APP_BACKEND_URL)
    useEffect(() => {
        document.title = "Home | QUBITWARE";
    }, []);
    return (
        <AnimationWrapper className="flex flex-col">
            {/* Hero section */}
            <section key={"hero-section"}>
                <div
                    className="container mx-auto py-16 flex flex-col items-center gap-y-8 
                    2xl:py-32
                "
                >
                    {/* Hero section */}
                    <GradientText
                        className="text-5xl font-extrabold py-2
                        md:text-6xl
                        lg:text-7xl
                        xl:text-8xl
                    "
                    >
                        Empowering IT <br /> Empowering You
                    </GradientText>
                    <p
                        className="text-xl text-gray-500 mb-12
                        lg:text-2xl
                    "
                    >
                        Empower your business with{" "}
                        <GradientText className="font-bold">
                            QUBITWARE
                        </GradientText>
                        . Our expert-led IT managed services, cloud services,
                        advanced cybersecurity measures, full-stack agency
                        tools, and more are designed to support your
                        organization’s goals.
                    </p>
                </div>
            </section>

            {/* services section */}
            <section
                key={"service-section"}
                className="bg-cblue py-12 text-left rounded-2xl
                lg:py-16
                "
            >
                <div
                    className="container mx-auto flex flex-col gap-8
                    md:gap-6
                    lg:gap-8 
                    "
                >
                    <div>
                        <h1 className="text-4xl text-white font-extrabold mb-4
                            lg:text-5xl
                        ">Our Offerings</h1>
                        <h1
                            className="text-2xl text-white-500 text-gray-200 font-thin
                            xl:text-2xl
                        "
                        >
                            <span className="bg-cgreen px-1 rounded-lg">
                                Reliable
                            </span>
                            {", "}
                            <span className="bg-cgreen px-1 rounded-lg">
                                customized
                            </span>{" "}
                            IT Services designed to support your business and give
                            you peace of mind.
                        </h1>
                    </div>
                    
                    <div
                        className="grid grid-cols-1 gap-6
                            sm:grid-cols-2
                            md:gap-8
                            lg:grid-cols-2
                            xl:grid-cols-3
                            2xl:grid-cols-4
                        ">
                        {services.map((service, index) => (
                            <ServiceCard
                                key={index}
                                {...service}
                                img_url={`/service_imgs/${index + 1}.png`}
                            />
                        ))}
                    </div>
                    <Link
                        className="mx-auto text-white text-base outline outline-2 hover:bg-white hover:text-cblue rounded-3xl px-4 py-2 font-bold cursor-pointer shadow-2xl shadow-black hover:shadow-none
                    "
                        to="/services/"
                    >
                        All Services
                    </Link>
                </div>
            </section>

            {/* Blogs section */}
            {
                blogsData.length < 3 ?
                    <></> :
                    <section
                        key={"blogs-section"}
                        className="py-12 bg-neutral-100
                            lg:py-16
                        "
                    >
                        <div className="container mx-auto flex flex-col text-left gap-8
                            ">
                            <div className="flex flex-col gap-2">
                                <GradientText
                                    children={"Latest Stories"}
                                    className="text-4xl font-extrabold
                                        lg:text-5xl
                                    "
                                />
                                <p
                                    className="text-lg text-slate-500 
                                lg:text-xl
                                xl:text-2xl
                            "
                                >
                                    Explore our latest blogs, articles, and stories to stay
                                    updated with the latest trends in the tech industry.
                                </p>
                            </div>

                            {/* Blogs */}
                            <div
                                className="grid grid-cols-1 gap-4
                                    md:grid-cols-2
                                    lg:grid-cols-1 lg:gap-8
                                    xl:grid-cols-3
                                "
                            >
                                <BlogCardBig blogData={blogsData[0]}/>
                                <BlogCardBig blogData={blogsData[1]}/>
                                <BlogCardBig blogData={blogsData[2]}/>
                            </div>

                            {/* More Blogs Button */}
                            <button
                                onClick={() => {
                                    navigate(`/blogs`);
                                }}
                                className="w-fit mx-auto py-2 px-4 text-base text-slate-400 hover:text-white font-extrabold bg-white hover:bg-slate-300 hover:outline-none outline outline-1 outline-slate-300 rounded-full cursor-pointer
                                md:text-lg
                                lg:mt-6
                            "
                            >
                                More Blogs
                            </button>
                        </div>
                    </section>
            }
            {/* Contact us section */}
            <section
                key={"contact-section"}
                className="py-12
                        lg:py-16
                "
                >
                <div className="container mx-auto">
                    <GradientContactCard
                        message={
                            "Ready to streamline your IT solutions? Get in touch with us today!"
                        }
                    />
                </div>
            </section>
            <Footer varient="blue" />
        </AnimationWrapper>
    );
}
