import BlogCard from "../components/BlogCard.component";
import { useEffect, useState } from "react";
import axios from "axios";
import "react-loading-skeleton/dist/skeleton.css";
import Tag from "../components/Tag.component";
import { Outlet, useLoaderData, useNavigate, Navigate } from "react-router-dom";
import Footer from "../components/Footer.component";
import BlogCardBig from "../components/BlogCardBig.component";
import GradientText from "../components/GradientText.component";
import BlogPost, { getBlogLoader } from "./BlogPost.page";

const fetchAllBlogsLoader = async () => {
    return await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/blog/all/`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

const fetchAllTagsLoader = async () => {
    return await axios(`${process.env.REACT_APP_BACKEND_URL}/blog/tags/`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const blogsPageDataLoader = async () => {
    return {
        "blogs" : await fetchAllBlogsLoader(),
        "blogTagsList" : await fetchAllTagsLoader()
    };
}

export const blogs_routes = [
    {
        path: '',
        element: <AllBlogs />,
        loader: blogsPageDataLoader,
    },
    {
        path: ':blog_id/:title',
        element: <BlogPost />,
        loader: getBlogLoader
    },
    {
        path: '*',
        element: <Navigate to="/not-found" />,
    }
];

export default function Blogs() {
    return (
        <Outlet/>
    );
}

function AllBlogs() {
    const { blogs, blogTagsList } = useLoaderData();
    const [selectedTag, setSelectedTag] = useState(null);
    const navigate = useNavigate();

    // const fetchBlogsByTag = async (tag) => {
    //     return await axios
    //         .get(`process.env.REACT_APP_BACKEND_URL/blog/tag?tag=${tag}`)
    //         .then((res) => {
    //             setBlogs(res.data);
    //             console.log(res.data);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // };

    const tagClickHandler = (tag, isSelected) => {
        if (isSelected) {
            navigate("/blogs");
            setSelectedTag(null);
            // fetchAllBlogs();
        } else {
            navigate(`/blogs?tag=${tag}`);
            setSelectedTag(tag);
            // fetchBlogsByTag(tag);
        }
    };

    useEffect(() => {
        document.title = `Blogs | QUBITWARE`
        window.scrollTo(0, 0);
    }, [blogs]);

    return (
        blogs.length == 0? 
        <h1>No Blogs Found</h1> :
        <div className="flex flex-col">
            {/* Featured Blogs Section */}
            <section className="py-2 pb-12">
                <div className="container mx-auto flex flex-col text-left gap-8
                ">
                    <div className="flex flex-col gap-2">
                        <GradientText
                            children={"Featured Stories"}
                            className="text-4xl font-extrabold
                        md:text-5xl
                    "
                        />
                        <p
                            className="text-lg text-slate-500
                        lg:text-xl
                        xl:text-2xl
                    "
                        >
                            Explore our latest blogs, articles, and stories to stay
                            updated with the latest trends in the tech industry.
                        </p>
                    </div>
                    {/* Blogs */}
                    <div className="grid grid-cols-1 gap-6
                        md:gap-8 md:grid-cols-2 auto-rows-max
                    ">  
                        <BlogCardBig blogData={blogs[0]} />
                        <BlogCardBig blogData={blogs[1]} />
                        <BlogCardBig blogData={blogs[2]} variant="horizontal" className="hidden xl:grid"/>
                        <BlogCardBig blogData={blogs[3]} variant="horizontal" className="hidden xl:grid"/>
                    </div>
                </div>
            </section>

            {/* All blogs section */}
            <section className="bg-neutral-100 rounded-2xl py-12
            ">
                <div className="container mx-auto grid grid-cols-1 gap-8
                md:py-8
                xl:grid-cols-3 
            ">


                    {/* Left Container - blogs card container*/}
                    <div className="flex flex-col border-slate-200
                        lg:mb-12
                        xl:border-r-[1px] xl:col-span-2 xl:col-start-1
                    ">
                        <div className="text-left text-3xl sticky top-0 bg-neutral-100
                            xl:text-4xl
                        ">
                            <GradientText
                                className="capitalize pl-4 text-2xl
                                md:text-3xl"
                                children={
                                    selectedTag ? selectedTag : "Latest Blogs"
                                }
                            />
                            <hr className="my-2 bg-black" />
                        </div>
                        <div className="flex flex-col gap-4">
                            {blogs?.length !== 0 ? (
                                blogs.map((blog) => (
                                    <>
                                        <BlogCard
                                            key={blog.id}
                                            details={{
                                                title: blog.title,
                                                short_description:
                                                    blog.short_description,
                                                banner: blog.banner,
                                                id: blog.id,
                                                created_at: blog.created_at,
                                                tags: blog.tags,
                                                read_time: blog.read_time,
                                            }}
                                        />
                                        <hr className="bg-black last:hidden" />
                                    </>
                                ))
                            ) : (
                                <h1 className="w-full bg-gray-200 my-2 h-12 rounded-xl flex justify-center items-center text-xl font-bold">
                                    No Blogs Found
                                </h1>
                            )}
                        </div>
                    </div>
                    {/* Right Container - tags container*/}
                    <div className="row-start-1
                        xl:cols-span-1 xl:col-start-3
                    ">
                        <div className="flex flex-col items-center gap-4
                            md:gap-8
                            xl:sticky xl:top-20
                        ">
                            <div className="flex flex-col gap-4">
                                <p className="text-xl
                                md:text-2xl
                            ">
                                    <span className="bg-cgreen text-white px-2 rounded-md">
                                        Recommended Topics
                                    </span>
                                </p>
                                <div className="flex justify-center flex-wrap gap-2
                                xl:text-lg xl:gap-6">
                                    {blogTagsList.map((tag) => {
                                        return (
                                            <Tag
                                                isSelected={
                                                    selectedTag === tag.name
                                                }
                                                key={tag.name}
                                                name={tag.name}
                                                onClickHandler={tagClickHandler}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer varient="blue" />
        </div>
    );
}
