import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

export default function ServiceCard({ title, description, img_url}) {
    return (
        <div className="grid grid-cols-1 group bg-white overflow-hidden rounded-xl shadow-none shadow-black border-0 relative
            xs:grid-cols-2
            sm:grid-cols-1
            lg:grid-cols-2
            xl:grid-cols-1
        ">
            <div className="w-full shadow-none shadow-slate-500 bg-blue-100 overflow-hidden
                sm:aspect-video
                lg:aspect-video lg:h-full
            ">
                <img className="w-full object-contain object-center h-48
                    sm:h-full
                    
                " src={img_url} alt="img" />
            </div>
            <div className="flex flex-col justify-start items-start gap-2 text-left p-4
            ">
                <h1 className="font-extrabold line-clamp-3 text-cblue text-lg
                ">
                    {title}
                </h1>
                <p className="text-base line-clamp-4 text-gray-500
                ">{description}</p>
                <Link to={`/services/${title}`} className="ml-auto mt-auto p-2 text-2xl -rotate-45 text-cblue rounded-full hover:bg-cblue bg-white/50 hover:text-white hover:rotate-0 duration-300 group-hover:animate-bounce
                ">
                    <FaArrowRight />
                </Link>
            </div>
        </div>
    );
}
