import GradientText from "../components/GradientText.component";
import { useNavigate, useLoaderData, useParams } from "react-router-dom";
import { useEffect } from "react";
import GradientContactCard from "../components/GradientContactCard.component";
import ImageBanner from "../components/ImageSlider.component";

export default function Service() {
    const navigate = useNavigate();
    const service_data = useLoaderData();
    
    const generateBannerImagesList = () => {
        const imagesList = [];
        service_data.images_list.forEach((img) => {
            imagesList.push(`/service_imgs/${img}`);
        })
        return imagesList;
    }

    useEffect(() => {
        document.title = `${service_data.title} | QUBITWARE`;
        window.scrollTo(0, 0);
    }, [service_data]);
    return (
        <div className="flex flex-col
        ">
            {/* Hero section */}
            <section>
                <div className="container mx-auto grid grid-cols-1 py-16 gap-4
                    md:gap-8
                    2xl:grid-cols-2 2xl:py-24
                ">
                    {/* Left container */}
                    <div className="flex flex-col justify-center items-start gap-6 text-left
                        2xl:gap-8       
                    ">
                        <GradientText
                            // style={{ textShadow: "0px 10px 15px gray" }}
                            className="text-5xl font-extrabold py-2
                                2xl:text-7xl                        
                            "
                            children={service_data.title}
                        />
                        <p className="text-xl text-gray-500">
                            {service_data.short_description}
                        </p>

                        <button
                            onClick={() => {
                                navigate(`/contact-us`);
                            }}
                            className="py-2 px-4 text-lg text-white hover:text-cblue font-extrabold bg-cblue hover:bg-white hover:outline hover:outline-cblue rounded-full cursor-pointer mt-4"
                        >
                            Contact Us
                        </button>
                    </div>
                    {/* Right Container */}
                    <div className="col-span-1 h-96 row-start-1
                        xl:col-start-2
                        ">
                        <ImageBanner images={generateBannerImagesList()} />
                    </div>
                </div>
            </section>

            {/* service tagline */}
            <section className="py-12
                lg:py-16
                ">
                <div className="container flex justify-evenly  text-gray-500 mb-4
                md:mb-8">
                    <div className="w-full overflow-hidden bg-gradient-to-b from-cblue to-cgreen relative -z-20">
                        <div
                            style={{
                                clipPath: "inset(0 6% 0 6%)",
                            }}
                            className="w-full h-full bg-white absolute inset-0 overflow-clip -z-10 scale-105"
                        ></div>

                        <h1 className="text-xl font-extrabold px-8 py-4 border-2 border-transparent bg-white m-4
                            md:text-2xl
                        ">
                            {service_data.highlighting_text}
                        </h1>
                    </div>
                </div>
            </section>

            {/* Service Features section */}
            <section className="bg-neutral-100 rounded-2xl">
                <div className="container mx-auto flex flex-col py-16 gap-4 text-left
                    md:gap-8
                    2xl:py-24
                ">
                    <h1 className="text-4xl text-left w-full font-extrabold text-black">
                        <GradientText children={"Key Advantages"} /> of QUBITWARE's {service_data.title} Services
                    </h1>

                    {/* Features */}
                    <div className="grid grid-cols-1
                        2xl:grid-cols-2 
                    ">
                        {service_data.service_features.map((feature, index) => {
                            return (
                                <div 
                                    key={index}
                                    className="flex p-4 border-b-[1px] last:border-b-0 border-slate-300 text-center
                                    lg:[&:nth-child(even)]:border-l-[1px] lg:[&:nth-child(n+5)]:border-b-0 
                                    ">
                                    <div className="w-full flex flex-col gap-4 text-gray-500">
                                        <h1 className="text-xl font-bold text-black">
                                            {feature.substring(0, feature.indexOf(':'))}
                                        </h1>
                                        <p className="text-base">
                                            {feature.substring(feature.indexOf(':') + 1)}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>

            {/* Why section */}
            <section className="py-12
                lg:py-16
                    ">
                <div className="container mx-auto grid grid-cols-1 gap-6
                    md:grid-cols-2
                ">
                    {/* left container */}
                    <div className="w-full h-96 relative rounded-3xl overflow-hidden bg-cover bg-center"
                        style={{ backgroundImage: `url(https://images.unsplash.com/photo-1536104968055-4d61aa56f46a?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)` }}
                    >
                        <div className="h-full w-full bg-cblue bg-opacity-40"
                        ></div>
                    </div>

                    {/* right container */}
                    <div className="flex flex-col justify-center items-start gap-4 text-left text-slate-500 px-6">
                        <GradientText
                            className="text-4xl font-extrabold"
                            children="Why QUBITWARE?"
                        />
                        <p className="text-xl">
                            {service_data.why_service}
                        </p>
                    </div>
                </div>
            </section>

            {/* Contact Us card */}
            <section className="py-12
                lg:py-16
                ">
                <div className="container mx-auto
                ">
                    <GradientContactCard
                        message={
                            `Interested in learning more about ${service_data.title} service from QUBITWARE?`
                        }
                    />
                </div>
            </section>
        </div>
    );
}
// linear-gradient(120deg, #006dab, #00acb2, #00b983)
