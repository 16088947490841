import React from "react";

export default function Tag({ onClickHandler, name, isSelected = false, key, clickable = false, className}) {
    const clickHandler = () => {
        if(!clickable) return;
        onClickHandler(name, isSelected);
    };
    return (
        <span
            key={key}
            onClick={clickHandler}
            className={`${className} px-3 py-1 rounded-full bg-gray-200 capitalize 
                ${
                    isSelected  
                        ? "outline outline-gray-500 outline-2 "
                        : " text-black"
                } 
                ${
                    clickable
                        ? "cursor-pointer hover:bg-gray-300"
                        : ""
                
                }`}
        >
            {name}
        </span>
    );
}
