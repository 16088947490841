import React from 'react';
import {motion} from 'framer-motion';

export default function AnimationWrapper({children, className}) {
    const variants = {
        hidden: { opacity: 0, x: -100},
        visible: { opacity: 1, x: 0, 
            transition: {
                duration: 1,
                staggerChildren: 10,
                delayChildren: 10,
            }
         },
    };
  return (
      <motion.div
          className={className}
            variants={variants}
            initial="hidden"
            animate="visible"

      >
          {children}
      </motion.div>
  );
}
