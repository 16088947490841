import { Link } from 'react-router-dom';
import './notFound.scss';

export default function NotFound() {
  return (
      <div className="not-found-container">
          <div >
              <div className="four_zero_four_bg">
                  <h1>404</h1>
              </div>

              <div className="contant_box_404">
                  <h3 className="h2">
                      Looks like you're lost
                  </h3>

                  <p>the page you are looking for is not avaible!</p>

                  <Link to='/' className="link_404">Go back to Home</Link>
              </div>
          </div>
      </div>
  )
}
