import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import useBreakpoint from '../utils/useBreakpoint'

const ImageBanner = ({images}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const breakpoint = useBreakpoint()

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [images.length]);

    return (
        <div className="mx-auto overflow-hidden h-full relative
        ">
            <motion.div
                initial={{
                    y:0
                }}
                animate={{
                    y: breakpoint == '2xl' ? `-${currentIndex * 100}%` : 0,
                    x: breakpoint == '2xl' ? 0 : `-${currentIndex * 100}%`,
                }}
                transition={{
                    duration:1
                }}
                className="flex flex-row h-full
                    xl:flex-col    
                "
                >
                {images.map((image, index) => (
                    <motion.div
                        initial={{
                            opacity: 0,
                        }}
                        animate={{
                            opacity: index == currentIndex? 1 : 0,
                        }}
                        transition={{
                            duration: 2
                        }}
                        key={index}
                        className="w-full h-full bg-contain bg-no-repeat bg-center flex-shrink-0 opacity-100"
                        style={{ backgroundImage: `url(${image})` }}
                    >   
                    </motion.div>
                ))}
            </motion.div>
        </div>
    );              
};

export default ImageBanner;
